import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

// components
import Hero from "../../utils/hero";
import Link from "../../utils/link";
import Page from "../../utils/page";
import Section from "../../utils/section";
import theme from "../../utils/theme";

// markup
const PersonalizarPage = () => {
  return (
    <Page
      lang="es"
      title="Personaliza tus toallitas y baberos"
      description="En unos pocos y sencillos pasos, también puedes tener tus toallitas desechables o tus baberos en tnt con tu propio diseño gráfico. Confía en nuestro equipo de expertos en personalización para dar un toque especial a la imagen de tu local."
      type="WebPage"
      readMore={["/contatti"]}
      image="/personalizza-prodotti.jpg"
    >
      <Hero
        title="Somos expertos en la personalización"
        description={
          <>
            Confía en nuestro equipo de expertos en personalización para darle
            un toque extra a la imagen de tu local.
            <br />
            ¡Personalizar tus productos puede parecer un proceso complicado,
            pero no lo es!
          </>
        }
        background={theme.palette.primary.main}
        backgroundImage={
          <StaticImage
            style={{ height: "100%" }}
            quality={100}
            src="../../images/backgrounds/fragranza-menta.jpg"
            alt="Fragancia de menta"
            layout="fullWidth"
          />
        }
      />

      <Section>
        <Grid container spacing={8}>
          <Grid item xs={12} md={6}>
            <Stack spacing={1}>
              <Box pb={3}>
                <StaticImage
                  style={{ height: "100%" }}
                  imgStyle={{ borderRadius: 16 }}
                  src="../../images/personalizzazione-salviette-monouso.jpg"
                  alt="Toallitas húmedas desechables cómo personalizarlas"
                  layout="fullWidth"
                />
              </Box>
              <Typography variant="h3" component="h2">
                Toallitas húmedas desechables: ¿cómo personalizarlas?
              </Typography>
              <Typography>
                En unos pocos y sencillos pasos, puedes tener tus propias{" "}
                <strong>toallitas húmedas desechables personalizadas</strong>.
                Aquí está cómo hacerlo:
              </Typography>
              <Stack component="ol" spacing={2}>
                <li>
                  Elige tu <strong>fragancia</strong> favorita:
                  <br /> limón, lime y canela, mojito, té verde, colonial,
                  lavanda y manzanilla, sweet fruit
                </li>
                <li>
                  Elige el tipo de <strong>formato</strong>:<br /> estándar
                  (10cm x 6,6cm), tamaño maxi (14cm x 7,3cm)
                </li>
                <li>
                  Elige el <strong>color del papel</strong>: blanco u havana
                </li>
                <li>
                  Envíanos tu <strong>logo</strong> que será impreso en el área
                  designada en la parte exterior del envoltorio.
                </li>
              </Stack>
              <Box pt={4}>
                <Link type="internal" href="/es/contactos" title="Contáctanos">
                  <Button size="large" variant="contained">
                    Contáctanos
                  </Button>
                </Link>
              </Box>
            </Stack>
          </Grid>

          <Grid item xs={12} md={6}>
            <Stack spacing={1}>
              <Box pb={3}>
                <StaticImage
                  style={{ height: "100%" }}
                  imgStyle={{ borderRadius: 16 }}
                  src="../../images/personalizzazione-bavagli-monouso.jpg"
                  alt="Baberos desechables de tnt cómo personalizarlos"
                  layout="fullWidth"
                />
              </Box>
              <Typography variant="h3" component="h2">
                Baberos desechables de tnt: ¿cómo personalizarlos?
              </Typography>
              <Typography>
                En unos pocos y sencillos pasos, también puedes tener tus{" "}
                <strong>baberos desechables personalizados</strong> en tnt. Aquí
                está cómo hacerlo:
              </Typography>
              <Stack component="ol" spacing={2}>
                <li>
                  Elige el tipo de <strong>formato</strong>:<br /> estándar (h
                  44,5cm x 37cm), maxi (h 44cm x 40cm), bebé (h 26cm x 24cm)
                </li>
                <li>
                  Envíanos tu <strong>logo</strong> que será impreso en el área
                  designada en el envoltorio exterior.
                </li>
              </Stack>
              <Box pt={4}>
                <Link type="internal" href="/es/contactos" title="Contáctanos">
                  <Button size="large" variant="contained">
                    Contáctanos
                  </Button>
                </Link>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Section>
    </Page>
  );
};

export default PersonalizarPage;
